<!--
 * @Author: xiaziwen
 * @Date: 2021-01-13 11:57:16
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-13 14:47:16
 * @FilePath: \crm-education\src\views\teacher\thesis\index.vue
-->
<template>
  <div>
    <div class="mb-10">
      <a-card class="cus-card no-border" :bordered="false">
        <template slot="title">
          <div
            class="section-header dec d-flex align-center justify-between flex-wrap"
          >
            <h2 class="base-title fs-18">毕业论文</h2>
          </div>
        </template>
        <a-form class="cus-form">
          <a-row :gutter="30">
            <a-col :md="{ span: 8 }" :xs="{ span: 12 }">
              <a-form-item label="教学点">
                <a-select
                  v-decorator="[
                    'field1',
                    {
                      rules: [
                        {
                          required: true,
                          message: '必填',
                        },
                      ],
                    },
                  ]"
                  placeholder="请选择教学点"
                >
                  <a-select-option value="1">
                    全部
                  </a-select-option>
                  <a-select-option value="2">
                    一般
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="{ span: 8 }" :xs="{ span: 12 }">
              <a-form-item label="年级">
                <a-select
                  v-decorator="[
                    'field2',
                    {
                      rules: [
                        {
                          required: true,
                          message: '必填',
                        },
                      ],
                    },
                  ]"
                  placeholder="请选择教学点"
                >
                  <a-select-option value="1">
                    全部
                  </a-select-option>
                  <a-select-option value="2">
                    一般
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="{ span: 8 }" :xs="{ span: 12 }">
              <a-form-item label="层次">
                <a-select
                  v-decorator="[
                    'field3',
                    {
                      rules: [
                        {
                          required: true,
                          message: '必填',
                        },
                      ],
                    },
                  ]"
                  placeholder="请选择教学点"
                >
                  <a-select-option value="1">
                    全部
                  </a-select-option>
                  <a-select-option value="2">
                    一般
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="{ span: 8 }" :xs="{ span: 12 }">
              <a-form-item label="专业">
                <a-select
                  v-decorator="[
                    'field4',
                    {
                      rules: [
                        {
                          required: true,
                          message: '必填',
                        },
                      ],
                    },
                  ]"
                  placeholder="请选择教学点"
                >
                  <a-select-option value="1">
                    全部
                  </a-select-option>
                  <a-select-option value="2">
                    一般
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="{ span: 8 }" :xs="{ span: 12 }">
              <a-form-item label="学生">
                <a-select
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  placeholder="请选择教学点"
                >
                  <a-select-option value="1">
                    全部
                  </a-select-option>
                  <a-select-option value="2">
                    一般
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="{ span: 8 }" :xs="{ span: 12 }">
              <a-form-item label="题目状态">
                <a-select
                  v-decorator="[
                    'field6',
                    {
                      rules: [
                        {
                          required: true,
                          message: '必填',
                        },
                      ],
                    },
                  ]"
                  placeholder="请选择教学点"
                >
                  <a-select-option value="1">
                    全部
                  </a-select-option>
                  <a-select-option value="2">
                    一般
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="{ span: 8 }" :xs="{ span: 12 }">
              <a-form-item label="论文批次">
                <a-select
                  v-decorator="[
                    'field7',
                    {
                      rules: [
                        {
                          required: true,
                          message: '必填',
                        },
                      ],
                    },
                  ]"
                  placeholder="请选择教学点"
                >
                  <a-select-option value="1">
                    全部
                  </a-select-option>
                  <a-select-option value="2">
                    一般
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="{ span: 8 }" :xs="{ span: 12 }">
              <a-form-item label="论文状态">
                <a-select
                  v-decorator="[
                    'field8',
                    {
                      rules: [
                        {
                          required: true,
                          message: '必填',
                        },
                      ],
                    },
                  ]"
                  placeholder="请选择教学点"
                >
                  <a-select-option value="1">
                    全部
                  </a-select-option>
                  <a-select-option value="2">
                    一般
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="{ span: 8 }" :xs="{ span: 12 }">
              <a-form-item label="论文题目">
                <a-select
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  placeholder="请输入论文题目"
                  v-decorator="[
                    'field9',
                    {
                      rules: [
                        {
                          required: true,
                          message: '必填',
                        },
                      ],
                    },
                  ]"
                >
                  <a-select-option value="1">
                    全部
                  </a-select-option>
                  <a-select-option value="2">
                    一般
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <div class="d-flex justify-center align-center">
            <a-button type="primary">搜索</a-button>
            <a-button class="cus-button ml-20" type="primary">重置</a-button>
          </div>
        </a-form>
      </a-card>
    </div>
    <div class="mb-10">
      <a-card class="cus-card no-border" :bordered="false">
        <div class="pt-20">
          <a-table
            class="cus-table fs-14"
            bordered
            :data-source="dataSource"
            :columns="columns"
            :pagination="false"
            rowKey="name1"
          ></a-table>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'teacher_thesis',
  data () {
    return {
      columns: [
        {
          title: '序号',
          width: '10%',
          align: 'center',
          customRender: (value, row, index) => {
            return <p>{index + 1}</p>
          }
        },
        {
          title: '姓名',
          dataIndex: 'name1',
          width: '10%',
          align: 'center'
        },
        {
          title: '手机号码',
          dataIndex: 'name2',
          width: '10%',
          align: 'center'
        },
        {
          title: '论文题目',
          dataIndex: 'name3',
          width: '10%',
          align: 'center'
        },
        {
          title: '论文题目状态',
          dataIndex: 'name4',
          width: '10%',
          align: 'center',
          customRender: (value, row, index) => {
            if (index % 2 === 0) {
              return <p class="color-orange-light">通过</p>
            } else {
              return <p class="color-red">审核中</p>
            }
          }
        },
        {
          title: '开题报告状态',
          dataIndex: 'name5',
          width: '10%',
          align: 'center',
          customRender: (value, row, index) => {
            if (index % 2 === 1) {
              return <p class="color-orange-light">通过</p>
            } else {
              return <p class="color-red">审核中</p>
            }
          }
        },
        {
          title: '定稿状态',
          dataIndex: 'name6',
          width: '10%',
          align: 'center',
          customRender: (value, row, index) => {
            if (index % 2 === 1) {
              return <p class="color-orange-light">通过</p>
            } else {
              return <p class="color-red">审核中</p>
            }
          }
        },
        {
          title: '论文成果',
          dataIndex: 'name7',
          width: '10%',
          align: 'center',
          customRender: (value, row, index) => {
            if (index % 2 === 1) {
              return <p class="color-orange-light">已发布</p>
            } else {
              return <p class="color-red">未发布</p>
            }
          }
        },
        {
          title: '操作',
          width: '20%',
          align: 'center',
          customRender: (value, row, index) => {
            return (
              <a-button class="cus-button" size="small" type="primary">
                进入
              </a-button>
            )
          }
        }
      ],
      dataSource: [
        {
          name1: '测试',
          name2: '测试',
          name3: '测试',
          name4: '测试',
          name5: '测试',
          name6: '测试',
          name7: '测试'
        },
        {
          name1: '测试1',
          name2: '测试',
          name3: '测试',
          name4: '测试',
          name5: '测试',
          name6: '测试',
          name7: '测试'
        },
        {
          name1: '测试3',
          name2: '测试',
          name3: '测试',
          name4: '测试',
          name5: '测试',
          name6: '测试',
          name7: '测试'
        }
      ]
    }
  },
  methods: {
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    }
  }
}
</script>
